<template>
  <div>
    <h5
      class="
        is-uppercase
        has-text-weight-medium has-text-centered has-text-primary
        p-2
        mb-2
        has-background-light
      "
    >
      Cadastrar Oftalmologista
    </h5>

    <div class="columns">
      <div class="column is-2">
        <Upload :id="doctor._id" :prefix="'doctor'" />
      </div>

      <div class="column pb-0">
        <ValidationObserver ref="form">
          <form @submit.prevent="addDoctor" @change="editDoctor">
            <div class="columns mb-1">
              <!-- treatment -->
              <div class="column is-2 pb-1">
                <ValidationProvider
                  name="treatment"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field
                    :message="errors[0]"
                    :type="errors[0] ? 'is-danger' : ''"
                  >
                    <b-select
                      v-model="doctor.treatment"
                      placeholder="Selecione..."
                    >
                      ]
                      <option value="Dr.">Dr.</option>
                      <option value="Dra.">Dra.</option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </div>
              <!-- name -->
              <div class="column is-4 pb-1">
                <ValidationProvider
                  name="name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field :type="errors[0] ? 'is-danger' : ''">
                    <b-input v-model="doctor.name" placeholder="nome"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <!-- crm -->
              <div class="column is-2 pb-1">
                <ValidationProvider
                  name="crm"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field :type="errors[0] ? 'is-danger' : ''">
                    <b-input v-model="doctor.crm" placeholder="CRM"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <!-- rqe -->
              <div class="column is-2 pb-1">
                <b-field>
                  <b-input v-model="doctor.rqe" placeholder="RQE"></b-input>
                </b-field>
              </div>
              <!-- assinante -->
              <div class="column is-2 pb-1">
                <b-field>
                  <b-checkbox v-model="doctor.subscriber">Assinante</b-checkbox>
                </b-field>
              </div>
            </div>
            <div class="columns mb-2">
              <div class="column is-4">
                <div class="buttons">
                  <b-button
                    v-show="!doctor._id"
                    native-type="submit"
                    type="is-primary"
                    >Enviar</b-button
                  >
                  <b-button
                    :disabled="!doctor.offices[officeIndex]._id"
                    @click="getPages()"
                    type="is-primary"
                    >Páginas</b-button
                  >
                </div>
              </div>
              <div class="column"></div>
            </div>
          </form>
        </ValidationObserver>

        <SearchClinic @onSelectedClinic="addOffice" />

        <form>
          <fieldset :disabled="!doctor.offices[officeIndex]._id">
            <!-- tabs offices -->
            <div class="tabs">
              <ul>
                <li
                  v-for="(office, index) in doctor.offices"
                  :key="index"
                  :class="{
                    'is-active':
                      officeIndex == index && doctor.offices[officeIndex]._id,
                  }"
                >
                  <a href @click.prevent="officeIndex = index">{{
                    office.name
                  }}</a>
                </li>
              </ul>
            </div>

            <!-- schedule -->
            <div class="columns">
              <div class="column">
                <b-field>
                  <b-input
                    :disabled="!doctor.subscriber"
                    @change.native="editOffice('maxagenda')"
                    v-model="doctor.offices[officeIndex].maxagenda"
                    placeholder="Max Agenda Key"
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input
                    :disabled="!doctor.subscriber"
                    @change.native="editOffice('whatsapp')"
                    v-model="doctor.offices[officeIndex].whatsapp"
                    v-mask="'(##) # #### ####'"
                    placeholder="Whatsapp"
                  ></b-input>
                </b-field>
              </div>
              <div class="column"></div>
            </div>

            <div class="columns">
              <!-- services -->
              <div class="column">
                <h6
                  class="
                    is-uppercase
                    has-text-weight-medium
                    p-2
                    mb-2
                    has-background-light
                  "
                >
                  Serviços
                </h6>
                <b-field
                  v-for="service in services"
                  :key="service._id"
                  class="mb-0"
                >
                  <b-checkbox
                    @input="editOffice('services')"
                    v-model="doctor.offices[officeIndex].services"
                    :native-value="service._id"
                  >
                    {{ service.name }}
                  </b-checkbox>
                </b-field>
              </div>
              <!-- covenants -->
              <div class="column">
                <h6
                  class="
                    is-uppercase
                    has-text-weight-medium
                    p-2
                    mb-2
                    has-background-light
                  "
                >
                  Convênios
                </h6>
                <b-field>
                  <b-autocomplete
                    rounded
                    :data="covenants"
                    placeholder="Convênio..."
                    icon="magnify"
                    field="name"
                    :loading="isFetching"
                    :clear-on-select="true"
                    @typing="getAsyncCovenant"
                    @select="(option) => addCovenant(option)"
                  >
                  </b-autocomplete>
                </b-field>

                <b-field
                  v-for="covenant in doctor.offices[officeIndex].covenants"
                  :key="covenant._id"
                  class="mb-0"
                >
                  <b-checkbox
                    @input="delCovenant(covenant._id)"
                    v-model="doctor.offices[officeIndex].covenants"
                    :native-value="covenant"
                  >
                    {{ covenant.name }}
                  </b-checkbox>
                </b-field>
              </div>
              <!-- filters -->
              <div class="column">
                <h6
                  class="
                    is-uppercase
                    has-text-weight-medium
                    p-2
                    mb-2
                    has-background-light
                  "
                >
                  Filtros
                </h6>
                <b-field
                  v-for="filter in filters"
                  :key="filter._id"
                  class="mb-0"
                >
                  <b-checkbox
                    @input="editOffice('filters')"
                    v-model="doctor.offices[officeIndex].filters"
                    :native-value="filter._id"
                  >
                    {{ filter.name }}
                  </b-checkbox>
                </b-field>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <!-- modal pages -->
    <b-modal
      v-model="openModalPages"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-modal
    >
      <template #default="props">
        <div class="modal-card" style="width: 650px">
          <header class="modal-card-head">
            <p class="modal-card-title">Páginas</p>
            <button type="button" class="delete" @click="props.close" />
          </header>
          <section class="modal-card-body">
            <!-- por endereço -->
            <b-collapse :open="false">
              <template #trigger>
                <p
                  class="
                    is-uppercase
                    has-text-weight-medium
                    p-2
                    mb-2
                    has-background-light
                  "
                >
                  <b-tag>{{ pages.pageAddress.length }}</b-tag
                  >Por Endereço
                </p>
              </template>
              <b-field v-for="page in pages.pageAddress" :key="page._id">
                <b-checkbox
                  @input="setPages()"
                  v-model="doctor.pages"
                  :native-value="page._id"
                  >{{ page.url }}</b-checkbox
                >
              </b-field>
            </b-collapse>

            <!-- por serviço -->
            <b-collapse :open="false">
              <template #trigger>
                <p
                  class="
                    is-uppercase
                    has-text-weight-medium
                    p-2
                    mb-2
                    has-background-light
                  "
                >
                  <b-tag>{{ pages.pageServices.length }}</b-tag
                  >Por Serviço
                </p>
              </template>
              <b-field v-for="page in pages.pageServices" :key="page._id">
                <b-checkbox
                  @input="setPages()"
                  v-model="doctor.pages"
                  :native-value="page._id"
                  >{{ page.url }}</b-checkbox
                >
              </b-field>
            </b-collapse>

            <!-- por convênio -->
            <b-collapse :open="false">
              <template #trigger>
                <p
                  class="
                    is-uppercase
                    has-text-weight-medium
                    p-2
                    mb-2
                    has-background-light
                  "
                >
                  <b-tag>{{ pages.pageCovenants.length }}</b-tag
                  >Por Convênio
                </p>
              </template>
              <b-field v-for="page in pages.pageCovenants" :key="page._id">
                <b-checkbox
                  @input="setPages()"
                  v-model="doctor.pages"
                  :native-value="page._id"
                  >{{ page.url }}</b-checkbox
                >
              </b-field>
            </b-collapse>

            <!-- por filtros -->
            <b-collapse :open="false">
              <template #trigger>
                <p
                  class="
                    is-uppercase
                    has-text-weight-medium
                    p-2
                    mb-2
                    has-background-light
                  "
                >
                  <b-tag>{{ pages.pageFilters.length }}</b-tag
                  >Por Filtro
                </p>
              </template>
              <b-field v-for="page in pages.pageFilters" :key="page._id">
                <b-checkbox
                  @input="setPages()"
                  v-model="doctor.pages"
                  :native-value="page._id"
                  >{{ page.url }}</b-checkbox
                >
              </b-field>
            </b-collapse>
          </section>
          <footer class="modal-card-foot">
            <b-button label="Fechar" @click="props.close" />
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Upload from "@/components/Upload.vue";
import SearchClinic from "@/components/SearchClinic.vue";
import debounce from "debounce";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});

const doctor = {
  treatment: "",
  name: "",
  crm: "",
  rqe: "",
  pages: [], //?
  subscriber: false,
  active: true,
  offices: [
    {
      _id: "",
      name: "",
      services: [],
      covenants: [],
      filters: [],
      whatsapp: "",
      maxagenda: "",
      active: true,
    },
  ],
};

export default {
  name: "Doctor",
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver,
    Upload,
    SearchClinic,
  },
  data() {
    return {
      doctor,
      officeIndex: 0,

      services: [],
      covenants: [],
      filters: [],
      pages: [],
      openModalOffice: false,
      openModalPages: false,
      isFetching: false,
    };
  },
  computed: {
    doctorId() {
      return this.$route.params.doctorId || this.doctor._id;
    },
  },
  created() {
    this.$http
      .get(`${process.env.VUE_APP_URI}doctor/filter_service`)
      .then((response) => {
        this.filters = response.data.filters;
        this.services = response.data.services;

        if (this.doctorId)
          this.$http
            .get(`${process.env.VUE_APP_URI}doctor/${this.doctorId}`)
            .then((response) => {
              if (response.data.offices.length == 0)
                response.data.offices = this.doctor.offices;

              this.doctor = JSON.parse(JSON.stringify(response.data));
            });
      });
  },

  methods: {
    //doctor
    addDoctor() {
      const self = this;
      self.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        self.$http
          .post(`${process.env.VUE_APP_URI}doctor`, self.doctor)
          .then((response) => {
            self.$set(self.doctor, "_id", response.data._id);
            self.$buefy.toast.open({
              message: "Dados Enviados",
              type: "is-success",
            });
          });
      });
    },
    editDoctor() {
      const self = this;
      if (!self.doctorId) return;
      self.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        const data = {
          treatment: self.doctor.treatment,
          name: self.doctor.name,
          crm: self.doctor.crm,
          rqe: self.doctor.rqe,
          subscriber: self.doctor.subscriber,
        };

        self.$http
          .put(`${process.env.VUE_APP_URI}doctor/${self.doctorId}`, data)
          .then(() => {
            self.$buefy.toast.open({
              message: "Dados Enviados",
              type: "is-success",
            });
          });
      });
    },

    //office
    addOffice(doctor) {
      const data = {
        name: doctor.name,
        locale: `${doctor.address.city}-${doctor.address.state}`,
        doctor: this.doctorId,
        clinic: doctor._id,
      };

      this.$http
        .post(`${process.env.VUE_APP_URI}office`, data)
        .then((response) => {
          if (response.data._id) {
            //se é o primeiro office do doctor
            if (!this.doctor.offices[0]._id) {
              this.doctor.offices[0]._id = response.data._id;
              this.doctor.offices[0].name = data.name;
            } else {
              this.doctor.offices.push({
                _id: response.data._id,
                name: data.name,
                services: [],
                covenants: [],
                filters: [],
                whatsapp: "",
                maxagenda: "",
                active: true,
              });
            }
          }
        });
    },
    //services
    //setService
    editOffice(key) {
      let data = {};
      data[key] = this.doctor.offices[this.officeIndex][key];
      this.$http.put(
        `${process.env.VUE_APP_URI}office/${
          this.doctor.offices[this.officeIndex]._id
        }`,
        data
      );
    },

    //covenants
    getAsyncCovenant: debounce(function (name) {
      if (!name.length || name.length < 3) {
        this.data = [];
        return;
      }
      this.isFetching = true;
      this.$http
        .get(`${process.env.VUE_APP_URI}_covenant/search/${name}`)
        .then((response) => {
          this.covenants = response.data;
          this.isFetching = false;
        });
    }, 500),
    addCovenant(selected) {
      if (selected) {
        const covenants = this.doctor.offices[this.officeIndex].covenants
          .concat(selected)
          .map((v) => v._id);

        this.$http
          .put(
            `${process.env.VUE_APP_URI}office//${
              this.doctor.offices[this.officeIndex]._id
            }`,
            { covenants }
          )
          .then(() =>
            this.doctor.offices[this.officeIndex].covenants.push(selected)
          );
      }
    },
    delCovenant(covenantId) {
      const covenants = this.doctor.offices[this.officeIndex].covenants
        .filter((f) => f._id != covenantId)
        .map((v) => v._id);

      this.$http
        .put(
          `${process.env.VUE_APP_URI}office//${
            this.doctor.offices[this.officeIndex]._id
          }`,
          { covenants }
        )
        .then(
          () =>
            (this.doctor.offices[this.officeIndex].covenants =
              this.doctor.offices[this.officeIndex].covenants.filter(
                (f) => f._id != covenantId
              ))
        );
    },

    //pages
    getPages() {
      const self = this;
      self.$http
        .get(`${process.env.VUE_APP_URI}_page/sugest/${self.doctorId}`)
        .then(function (response) {
          self.pages = response.data;
          self.openModalPages = true;
        });
    },
    setPages() {
      this.$http.put(`${process.env.VUE_APP_URI}doctor/${this.doctorId}`, {
        pages: this.doctor.pages,
      });
    },
  },
};
</script>
