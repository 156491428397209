<template>
  <b-field>
    <b-autocomplete
      rounded
      :data="clinics"
      placeholder="Clínica..."
      icon="magnify"
      field="name"
      :loading="isFetching"
      :clear-on-select="true"
      @typing="getAsyncClinic"
      @select="(option) => setClinic(option)"
    >
    </b-autocomplete>
  </b-field>
</template>
<script>
import debounce from "debounce";
export default {
  name: "SearchClinic",
  data() {
    return {
      clinics: [],
      isFetching: false,
    };
  },
  methods: {
    getAsyncClinic: debounce(function (name) {
      if (!name.length || name.length < 3) {
        this.data = [];
        return;
      }
      this.isFetching = true;
      this.$http
        .get(`${process.env.VUE_APP_URI}clinic/search/${name}`)
        .then((response) => {
          this.clinics = response.data;
          this.isFetching = false;
        });
    }, 500),
    setClinic(selected) {
      if (selected) this.$emit("onSelectedClinic", selected);
    },
  },
};
</script>